import * as React from 'react';
import { graphql, Link } from 'gatsby';
import {
  Layout,
  SEO,
} from '../components/_index';

// markup
const NotFoundPage = () => {
  return (
    <Layout>
      <SEO title="404 not found" ogtype={'website'} />
      <main id="notFound">
        <h2 className="tit">ページが存在しません<span>404 Not Found</span></h2>
        <h3>お客様のアクセスしたページ（URL）が<br className="sp" />見つかりませんでした。<br className="sp" />以下のような理由が考えられます。</h3>
        <ul>
          <li>アドレスが正しく入力されていない場合</li>
          <li>ページが移動したか、掲載期間が終了した場合</li>
        </ul>
        <p>誠に恐れ入りますが、再度アドレスを入力しても正しく表示されない場合は、<br className="pc" />TOPページから該当する内容をお探しください。</p>
        <div className="btnType02 btn"><Link to="/">TOPへ戻る</Link></div>
      </main>

    </Layout>
  );
};

export default NotFoundPage;
